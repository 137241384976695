/* ========================================================================
   Component: panels.less
 ========================================================================== */
 

// Customized panel styles
.panel {
  box-shadow: 0 1px 0 rgba(0,0,0,.1);
  .panel-heading {
    border-bottom-width: 0;
    .panel-title {
      // font-weight: 300;
    }
    a {
      text-decoration: none !important;
    }
  }
  &.panel-default {
    // box-shadow: 0 0 1px @brand-noir;
    .panel-heading {
      border-bottom-width: 1px;
    }
  }

  &.panel-transparent {
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 #000;
    .panel-heading,
    .panel-body {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
}



//
// Adds support to use more components inside panels
//

.panel {
  .table {
    margin-bottom: 0;
    border: 0;
  }
}

.panel-flat {
  margin: 0 !important;
  border: 0;
}

.panel-group {
  .panel-flat {
    box-shadow: 0 0 0 #000;
    &:first-child {
      border-radius: 4px 4px 0 0;
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
}

.panel-heading {
  
  > .btn, > .btn-group {
    margin-top: -5px;
  }
  > .btn-sm, > .btn-group-sm {
    margin-top: -3px;
  }
  > .btn-xs, > .btn-group-xs {
    margin-top: -1px;
  }  
  // right floated labels adjust position
  > .label.pull-right {
    margin-top: 3px;
    + .label.pull-right {
      margin-right: 10px;
    }
  }
}

.panel-footer {
  .pagination {
    margin: 0;
  }
  .radial-bar {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
}

// Active class for collapse elements 
.panel-heading-active {
  background-color: @brand-success !important;
  font-weight: bold;
  font-size: 16px;
  > a {
    padding: 15px 0;  // Adding padding here improves mobile touch
    color: #fff !important;
    .transition(padding .5s ease);
  }
  + .panel-collapse:after {
    content: "";
    display: block;
    width: 60%;
    margin: 0 auto;
    height: 0;
    border-bottom: 1px solid #e1e2e3;
  }
}

.panel-inverse {
  .panel-variant(@panel-global-border; #fff; @brand-inverse; @brand-inverse);
}

.panel-turquoise {
  .panel-variant(@panel-global-border; #fff; @brand-turquoise; @brand-turquoise);
}

.panel-pink {
  .panel-variant(@panel-global-border; #fff; @brand-pink; @brand-pink);
}

.panel-purple {
  .panel-variant(@panel-global-border; #fff; @brand-purple; @brand-purple);
}

.panel-orange {
  .panel-variant(@panel-global-border; #fff; @brand-orange; @brand-orange);
}
