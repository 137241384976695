/* ========================================================================
   Component: animate.less
 ========================================================================== */

@charset "UTF-8";

@animate-fade-distance: 18px;

@animate-default-duration: .5s;
@animate-view-duration: .35s;

// Angular standard animation state
// ----------------------------------- 
[ui-view].ng-leave {
    display: none;
    &.ng-fluid {
      display: block;
    }
}

.ng-fluid.ng-animate {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden
}

// Animations adapted for ngAnimate
// ----------------------------------- 

.ng-fadeInLeft2 {
  &.ng-enter { .animation(fadeInLeft2 @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutRight @animate-view-duration); }
}

.ng-fadeIn {
  &.ng-enter { .animation(fadeIn @animate-view-duration);  }
  &.ng-leave { .animation(fadeOut @animate-view-duration); }
}

.ng-fadeInUp {
  &.ng-enter { .animation(fadeInUp @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutDown @animate-view-duration); }
}

.ng-fadeInDown {
  &.ng-enter { .animation(fadeInDown @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutUp @animate-view-duration); }
}

.ng-fadeInRight {
  &.ng-enter { .animation(fadeInRight @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutLeft @animate-view-duration); }
}

.ng-fadeInLeft {
  &.ng-enter { .animation(fadeInLeft @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutRight @animate-view-duration); }
}

.ng-fadeInUpBig {
  &.ng-enter { .animation(fadeInDownBig @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutDownBig @animate-view-duration); }
}

.ng-fadeInDownBig {
  &.ng-enter { .animation(fadeInUpBig @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutUpBig @animate-view-duration); }
}

.ng-fadeInRightBig {
  &.ng-enter { .animation(fadeInRightBig @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutLeftBig @animate-view-duration); }
}

.ng-fadeInLeftBig {
  &.ng-enter { .animation(fadeInLeftBig @animate-view-duration);  }
  &.ng-leave { .animation(fadeOutRightBig @animate-view-duration); }
}

.ng-fadeOutZoom {
  &.ng-enter { .animation(zoomIn 1s @easeOutQuint);  }
  &.ng-leave { .animation(fadeOut 1s @easeOutQuint); }
}


// Animate.css - http://daneden.me/animate  
// animo support
// ----------------------------------- 

.animated {
  -webkit-animation-duration: @animate-default-duration;
  animation-duration: @animate-default-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInLeft2 {
  -webkit-animation-name: fadeInLeft2;
  animation-name: fadeInLeft2;
}  

@-webkit-keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-@animate-fade-distance, 0, 0);
    transform: translate3d(-@animate-fade-distance, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-@animate-fade-distance, 0, 0);
    -ms-transform: translate3d(-@animate-fade-distance, 0, 0);
    transform: translate3d(-@animate-fade-distance, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

// Timing functions
// ----------------------------------- 

// Cubic
@easeInCubic:  cubic-bezier(0.550, 0.055, 0.675, 0.190);
@easeOutCubic:  cubic-bezier(0.215, 0.610, 0.355, 1.000);
@easeInOutCubic :  cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
@easeInCirc:  cubic-bezier(0.600, 0.040, 0.980, 0.335);
@easeOutCirc:  cubic-bezier(0.075, 0.820, 0.165, 1.000);
@easeInOutCirc:  cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
@easeInExpo:  cubic-bezier(0.950, 0.050, 0.795, 0.035);
@easeOutExpo:  cubic-bezier(0.190, 1.000, 0.220, 1.000);
@easeInOutExpo:  cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
@easeInQuad:  cubic-bezier(0.550, 0.085, 0.680, 0.530);
@easeOutQuad:  cubic-bezier(0.250, 0.460, 0.450, 0.940);
@easeInOutQuad:  cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
@easeInQuart:  cubic-bezier(0.895, 0.030, 0.685, 0.220);
@easeOutQuart:  cubic-bezier(0.165, 0.840, 0.440, 1.000);
@easeInOutQuart :  cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
@easeInQuint:  cubic-bezier(0.755, 0.050, 0.855, 0.060);
@easeOutQuint:  cubic-bezier(0.230, 1.000, 0.320, 1.000);
@easeInOutQuint :  cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
@easeInSine:  cubic-bezier(0.470, 0.000, 0.745, 0.715);
@easeOutSine:  cubic-bezier(0.390, 0.575, 0.565, 1.000);
@easeInOutSine:  cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
@easeInBack:  cubic-bezier(0.600, -0.280, 0.735, 0.045);
@easeOutBack:  cubic-bezier(0.175,  0.885, 0.320, 1.275);
@easeInOutBack:  cubic-bezier(0.680, -0.550, 0.265, 1.550);