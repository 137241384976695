/* ========================================================================
   Component: table-ngtable.less
 ========================================================================== */

body {
  
  // Basic styles override
  .ng-table th.sortable.sort-desc,
  .ng-table th.sortable.sort-asc {
    background-color: #fff;
    text-shadow: 0 0 0 rgba(255, 255, 255, 0);
  }

  .ng-table th.sortable div:after,
  .ng-table th.sortable div:before {
    border-color: @gray-dark transparent;
  }
  .ng-table th.sortable div:before {
    border-top-color: @gray-dark;
  }
  .ng-table th.sortable.sort-desc div:after {
    border-top-color: @gray-dark;
  }

  [ng-table-pagination] {
    padding: 15px 5px;
  
    .ng-table-pagination {
      margin: 0;
    }

  }

  // Colors cell variations (red/green)
  .ng-table .plus, .ng-table .minus {
      font-weight: bold;
      padding-left: 18px;
      position: relative;
  }
  .ng-table .plus:before, .ng-table .minus:before {
      content:"";
      border-width: 4px;
      border-style:solid;
      left: 8px;
      top: 50%;
      position: absolute;
      margin-top: -2px;
  }
  .ng-table .plus {
      color: green;
  }
  .ng-table .plus:before {
      border-color: green;
      border-top:none;
      border-left:4px solid transparent;
      border-right:4px solid transparent;
  }
  .ng-table .minus {
      color: red;
  }
  .ng-table .minus:before {
      border-color: red;
      border-bottom:none;
      border-left:4px solid transparent;
      border-right:4px solid transparent;
  }
  
  // Selected rows
  .ng-table-rowselected tr {
    cursor: pointer;
  }
  
  .ng-table-pager {
    .clearfix;
  }

}