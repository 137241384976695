/* ========================================================================
   Component: button-extra.less
 ========================================================================== */

// Standard buttons redesign

.btn {
  -webkit-appearance: none;
  outline: none !important;
  .transition(all .1s);

  &.btn-link {
    .box-shadow(0 0 0 #000) !important;
    border: 0;
  }
  &.btn-default {
    border-color: @btn-default-border;
  }
 
  .input-group .input-sm + .input-group-btn & {
    font-size: 13px;
  }
  &:active,
  &.active {
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.15));
  }
}

.btn-group.open .dropdown-toggle {
  .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.15));
}


// 
// Extension for Bootstrap buttons
// ------------------------------------

// New colors

.btn-inverse   { .button-variant(#fff; @brand-inverse; @brand-inverse); }
.btn-turquoise { .button-variant(#fff; @brand-turquoise; transparent;); }
.btn-purple    { .button-variant(#fff; @brand-purple; transparent;);    }
.btn-pink      { .button-variant(#fff; @brand-pink; transparent;);      }
.btn-orange    { .button-variant(#fff; @brand-orange; transparent;);    }

.btn-outline {
  background-color: transparent;
  border-color: #fff;
  &:hover, &:focus {
    background-color: #fff;
    color: @brand-primary;
  }
}

// Flat square button

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000;
}

// Extra size

.btn-xl {
  padding: 20px 16px;
  font-size: 18px;
}

// Button square 

.btn-square {
  border-radius: 0;
}


// Button pill

.btn-pill-left {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px;
}

.btn-pill-right {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px;
}

// Rounded buttons

.btn-oval {
  &:extend(.btn-pill-right);
  &:extend(.btn-pill-left);
}

// Labels for buttons
// --------------------------------------------------

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-label {
  position: relative;
  display: inline-block;
  min-width: 30px;
  background: transparent;
  .button-label-size(@padding-base-vertical; @padding-base-horizontal; @border-radius-base);
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 0;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

.btn-lg .btn-label {
  .button-label-size(@padding-large-vertical; @padding-large-horizontal; @border-radius-large);
}
.btn-sm .btn-label {
  .button-label-size(@padding-small-vertical; @padding-small-horizontal; @border-radius-small);
}
.btn-xs .btn-label {
  .button-label-size(1px; 5px; @border-radius-small);
}

// BUTTONS LABEL mixin
// --------------------------------------------------

.button-label-size(@padding-vertical; @padding-horizontal; @border-radius) {
  padding: @padding-vertical @padding-small-horizontal;
  left: (-1 * @padding-horizontal);
  border-radius: (@border-radius - 1px) 0 0 (@border-radius - 1px);

  &.btn-label-right {
    left: auto;
    right: (-1 * @padding-horizontal);
    border-radius: 0 (@border-radius - 1px) (@border-radius - 1px) 0;
  }
}

// Fixed width buttons
// --------------------------------------------------

@btn-fw-sm:       80px;
@btn-fw:          120px;
@btn-fw-lg:       150px;

.btn-fw {
  min-width: @btn-fw;
  &.btn-sm {
    min-width: @btn-fw-sm;
  }
  &.btn-lg {
    min-width: @btn-fw-lg;
  }

}



