/* ========================================================================
   Component: toaster.less
 ========================================================================== */

// Redefined toaster colors
body {
    #toast-container {
      top: @header-hg + 5 !important;
    }
    .toast {
      background-color: @brand-primary;
    }
    .toast-success {
      background-color: @brand-success;
    }
    .toast-error {
      background-color: @brand-danger;
    }
    .toast-info {
      background-color: @brand-info;
    }
    .toast-wait {
      background-color: @brand-purple;
    }
    .toast-warning {
      background-color: @brand-warning;
    }
}