/* ========================================================================
   Component: gmap.less
 ========================================================================== */

.gmap {
  
  height: 300px;
  
  /* 
  Fixes Bootstrap issues with Google Maps
  see http://stackoverflow.com/a/9170756 
  */
  img { 
    max-width: none;
  }
}
