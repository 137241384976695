/* ========================================================================
   Component: form-validation.less
   (http://mdo.github.io/table-grid)
 ========================================================================== */

@media @tablet {

  // Add `.table-grid` for the table 
  .table-grid {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }

  // Add `.col` for the table cells, or columns
  .col {
    display: table-cell;
    vertical-align: top;
    height: 100%;

    // vertical center a col
    &.col-full-center {
      vertical-align: middle;
    }

  }

  // Set the widths 
  .col-1 { width: 8.333333%; }
  .col-2 { width: 16.666667%; }
  .col-3 { width: 25%; }
  .col-4 { width: 33.333333%; }
  .col-5 { width: 41.666667%; }
  .col-6 { width: 50%; }
  .col-7 { width: 58.333333%; }
  .col-8 { width: 66.666667%; }
  .col-9 { width: 75%; }
  .col-10 { width: 83.333333%; }
  .col-11 { width: 91.666667%; }
  .col-12 { width: 100%; }
  
  // fixed the widths 
  .col-tiny {  &:extend(.wd-tiny); }
  .col-xxs  {  &:extend(.wd-xxs);  }
  .col-xs   {  &:extend(.wd-xs);   }
  .col-sm   {  &:extend(.wd-sm);   }
  .col-sd   {  &:extend(.wd-sd);   }
  .col-md   {  &:extend(.wd-md);   }
  .col-lg   {  &:extend(.wd-lg);   }
  .col-xl   {  &:extend(.wd-xl);   }
  .col-xxl  {  &:extend(.wd-xxl);  }
  .col-wide {  &:extend(.wd-wide); }
  .col-auto {  &:extend(.wd-auto); }

  // Padded columns 
  .table-grid-padded {
    margin-left:  -1rem;
    margin-right: -1rem;
  }
  .table-grid-padded .table-grid {
    border-spacing: 1rem 0;
  }

  // Vertically center grid content
  // Requires content within the column to be inline or inline-block.

  .table-grid-align-middle .col {
    vertical-align: middle;
  }

}
