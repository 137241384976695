.palette {
    background-color: #fff;
    padding: 10px 10px 15px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 0px #e2e8eb;
    -moz-box-shadow: 0px 2px 0px #e2e8eb;
    -ms-box-shadow: 0px 2px 0px #e2e8eb;
    -o-box-shadow: 0px 2px 0px #e2e8eb;
    box-shadow: 0px 2px 0px #e2e8eb;
    margin: 30px 0;
    max-width: 100%
}

.color {
    height: 140px
}

.info {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: -5px;
    font-size: 15px;
    font-weight: bold;
    font-family: "AvenirNextLTProBold", "Lucida Grande", Arial, sans-serif;
    position: relative
}

.info li {
    list-style: none;
    display: block
}

.info span {
    font-size: 24px;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    color: #839199
}

.info .clipboard {
    right: 0;
    cursor: pointer
}

.info .icon-globe {
    right: 30px
}

.info li:nth-child(1) {
    text-transform: uppercase;
    color: #414b55
}

.info li:nth-child(2) {
    text-transform: lowercase;
    color: #839199
}
