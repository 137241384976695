/* ========================================================================
   Component: slim-scroll.less
 ========================================================================== */

@slim-scroll-bar-bg:      fade(#000, 35);
@slim-scroll-rail-bg:     fade(#000, 15);

// New scrollable element
scrollable {
  display: block;
}

// Important to override js inline styles
.slimScrollBar {
  opacity: 0 !important;
  background-color: @slim-scroll-bar-bg !important;
  border: 0 !important;
  border-radius: 1px !important;
  .transition(opacity .3s ease);
}

.slimScrollDiv:hover {
  .slimScrollBar {
    opacity: 0.4 !important;
  }
}

.slimScrollRail {
  opacity: 1 !important;
  background-color: @slim-scroll-rail-bg !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0;
}