/* ========================================================================
   Component: chat.less
 ========================================================================== */

.chat {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 6px;

  li {
    margin-bottom: 10px;
    padding-bottom: 5px;
    .chat-body {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
      background-color: #fff;
      border: 1px solid @gray-light;      
      p {
        margin: 0;
        color: @text-muted;
      }
    }
    .chat-header {
      padding: 10px;
    }
    .chat-msg {
      padding: 10px;
      border-radius: @border-radius-small;
    }

    &.left .chat-body {
        margin-left: 60px;
        .chat-msg {
        }
    }

    &.right .chat-body {
        margin-right: 60px;
        .chat-msg {
        }
    }

  }
}

.panel .slidedown .glyphicon, .chat .glyphicon {
    margin-right: 5px;
}
