/* ========================================================================
   Component: datepicker.less
 ========================================================================== */
 
[datepicker-popup] + ul {

  .btn-info > span {
    color: #fff;
  }

}


.datepicker {
  max-width: 100%;
  overflow-y: auto;
  table {
    width: 100%;
  }
}