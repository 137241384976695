/* Open Sans @font-face kit */

@font-path:     '../vendor/open-sans-fontface/fonts';

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url('@{font-path}/Light/OpenSans-Light.eot');
  src: url('@{font-path}/Light/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}/Light/OpenSans-Light.woff') format('woff'),
       url('@{font-path}/Light/OpenSans-Light.ttf') format('truetype'),
       url('@{font-path}/Light/OpenSans-Light.svg#OpenSansLight') format('svg');
  font-weight: 300;
  font-style: normal;
}
/* END Light */

/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url('@{font-path}/Regular/OpenSans-Regular.eot');
  src: url('@{font-path}/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}/Regular/OpenSans-Regular.woff') format('woff'),
       url('@{font-path}/Regular/OpenSans-Regular.ttf') format('truetype'),
       url('@{font-path}/Regular/OpenSans-Regular.svg#OpenSansRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}
/* END Regular */

/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url('@{font-path}/Italic/OpenSans-Italic.eot');
  src: url('@{font-path}/Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}/Italic/OpenSans-Italic.woff') format('woff'),
       url('@{font-path}/Italic/OpenSans-Italic.ttf') format('truetype'),
       url('@{font-path}/Italic/OpenSans-Italic.svg#OpenSansItalic') format('svg');
  font-weight: 400;
  font-style: italic;
}
/* END Italic */

/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url('@{font-path}/Semibold/OpenSans-Semibold.eot');
  src: url('@{font-path}/Semibold/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
       url('@{font-path}/Semibold/OpenSans-Semibold.woff') format('woff'),
       url('@{font-path}/Semibold/OpenSans-Semibold.ttf') format('truetype'),
       url('@{font-path}/Semibold/OpenSans-Semibold.svg#OpenSansSemibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */

