/* ========================================================================
   Component: documentation.less
 ========================================================================== */

// 
// This styles are based on the markup generated by the plugin flatdoc
// 

// Margins
@sidepad:   30px;  // Padding to the left of the sidebar

// Colors
@txt:       #505050;
@accent:    @gray-dark;

// Dimensions
@sidebar-width:   180px;
@content-width:   550px;
@pre-width:       380px;

@content-padding: 20px;

// element generated from directive
flatdoc {
  display: block;
}

[role~="flatdoc"] {
  position: relative;
  width: 100%;
  height: 100%;

  @media @tablet {
    .clearfix;
  }
}

[role~="flatdoc-menu"] {
  position: relative;
  font-size: 14px;
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    .flatdoc-menu-items()
  }

  // Affix menu
  @media @tablet {
    float: left;
    width: @sidebar-width;
    height: auto;
    padding-left: 10px;

    > ul {
      padding-top: 10px;
      max-height: 100%;
      overflow: auto;
      padding-bottom: @footer-hg;
      width: inherit;
    }
  
  }
}
  
[role~="flatdoc-content"] {
  width: 100%;
  padding-left: @content-padding;
  padding-right: @content-padding;
  padding-top: 1px;
  padding-bottom: 50px;
  background-color: #fff;

  @media @tablet {
    padding-left: @sidebar-width + @content-padding;
    height: 100%;
  }
 
  pre {
    background-color: @gray-lighter;
    border: 1px solid @gray-light;
    code {
      display: inline-block;
      white-space: pre;
      .string, .number {
        color: @brand-success-dark;
      }
      .init {
        color: @brand-success-dark;
      }
      .keyword {
        color: @gray-dark;
        font-weight: bold;
      }
      .comment {
        color: @text-muted;
      }
    }
  }
  
  > h1 {
    padding: 12px 0;
    margin: 0;
    line-height: 1;
  }

  > h2 {
    padding: 20px;
    border-top: 1px solid @gray-light;
    margin: 0 -20px;
    font-weight: bold;
    &:first-child {
      border-top: 0;
    }
  }

  .button {
    .btn;
    .btn-primary;
  }
}




.flatdoc-menu-items() {
  // Menu items
  & {
    
    &.level-1 > li ,
    &.level-2 > li {
      > a {
        text-transform: uppercase;
        font-weight: bold;
      }
      + li > a {
        border-bottom: 1px solid @gray-light;
      }
    }    

    a {
      position: relative;
      display: block;
      padding: 6px;
      border-right: 2px solid transparent;

      &, &:visited {
        color: @accent;
      }
      &:hover {
        color: @accent*0.8;
        background-color: rgba(255,255,255,.75);
        text-decoration: none;
      }

      &.level-1,
      &.level-2 {
        font-weight: 600;
      }

      &.level-3 {
        font-weight: normal;
        font-size: 12px;
        padding-left: 15px;
      }

      &.active {
        border-right-color: @gray-dark;
        &, &:visited, &:hover {
          color: @txt !important;
        }
        font-weight: bold !important;
      }
    }
  }
}